$greyQo-font: 'Grey Qo', cursive;
$italiano-font: 'Italianno', cursive;
$tangerine-font: 'Tangerine', cursive;

$site-heading-font: $tangerine-font;
$site-heading-font-family-upper: $tangerine-font;
$site-heading-font-family-lower: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
$site-heading-font-size-upper: 6rem;
$site-heading-font-size-lower: 1rem;

.site-heading {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: $site-heading-font-family-upper !important;
  text-transform: none;
  font-weight: 800;

  .site-heading-upper {
    font-family: $site-heading-font-size-upper;
    font-size: $site-heading-font-size-upper;
  }
  .site-heading-lower {
    font-family: $site-heading-font-family-lower !important;
    font-size: $site-heading-font-size-lower;
    text-transform: uppercase !important;
    line-height: 1rem;
  }
}

#mainNav .navbar-brand {
  font-family: $tangerine-font !important;
  font-size: 2.5em;
  text-transform: capitalize !important;
}

